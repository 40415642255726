<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="server.connection"
          >Server SQLs: {{ server.connection.database !== null ? server.connection.database.toUpperCase() : '-' }}</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <mf-button text label="Adicionar" @click="addSqlField = true"> </mf-button>
        </v-toolbar-items>
      </v-toolbar>

      <!-- queries -->
      <v-container v-if="server.configuration">
        <div v-for="(value, key) in server.configuration.sqls" :key="key" class="box">
          <v-card-title class="pa-0">{{ key }}</v-card-title>
          <v-alert v-if="key && key.toLowerCase() === 'vendas' && isHybrid" class="mt-2" width="900" type="error" dense outlined
            >A query de <strong>VENDAS</strong> para clientes híbridos deve sempre ter a propriedade <strong>COD_ID_CLIENTE</strong> como CPF do
            cliente.</v-alert
          >
          <mf-toggle v-model="server.configuration.sqls[key].inherit" class="my-2" hide-details color="#334A58" label="Herdar do ERP" />
          <v-spacer />
          <div class="my-4">
            <mf-button outlined color="error" label="Deletar Query" class="mr-2" @click="openChangesDelete(key)"></mf-button>
            <mf-button outlined color="primary" label="Atualizar Query" class="mr-2" @click="openChangesEdit(key, value)"></mf-button>
            <mf-button
              v-if="server.integrator !== 'database_mongo_integrator'"
              label="Query Herdada"
              outlined
              color="success"
              @click="showErpQuery(key)"
            ></mf-button>
          </div>
          <sql-builder :show="server.configuration.sqls[key].inherit" :sql.sync="server.configuration.sqls[key]" class="mb-5" itemscope />
          <v-divider />
        </div>
        <change-dialog v-if="changesDialogEdit" :dialog.sync="changesDialogEdit" :message.sync="changesMessage" @proceed="updateQuery(key, value)" />
        <change-dialog v-if="changesDialogDelete" :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeQuery(key)" />
      </v-container>
    </v-card>

    <!-- add query modal -->
    <v-dialog v-if="server.configuration" v-model="addSqlField" width="800">
      <v-card class="pa-4">
        <v-card-title class="headline"><v-icon class="mb-2 mr-2">mdi-database-outline</v-icon> Adicionar Query</v-card-title>
        <v-container>
          <v-text-field v-model="sqlKey" solo-inverted placeholder="Nome" />
          <v-switch v-model="inherit" hide-details color="primary" label="Inherit" class="mb-4" />
          <sql-builder :show="inherit" :sql.sync="sql" class="mb-5" itemscope />
        </v-container>
        <div v-if="server.erpServer && server.erpServer.sqls" class="erp-queries">
          <h4>ERP Queries:</h4>
          <v-list>
            <v-list-item-group v-for="key in Object.keys(server.erpServer.sqls)" :key="key">- {{ key }}</v-list-item-group>
          </v-list>
        </div>
        <v-divider />
        <v-card-actions class="mt-2">
          <v-btn width="240" outlined color="error" @click="addSqlField = false"><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
          <v-btn width="240" color="success" @click="saveInsertManager"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
        </v-card-actions>
        <change-dialog v-if="changesDialogInsert" :dialog.sync="changesDialogInsert" :message.sync="changesMessage" @proceed="addFields" />
      </v-card>
    </v-dialog>

    <!-- erp inherited query -->
    <v-dialog v-if="server.erpServer" v-model="erpInheritedQuery" width="800">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="erpInheritedQuery = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="server.erpServer && server.erpServer.acronym">{{
          getSafe(() => server.erpServer.acronym + ' - ' + inheritedQuery.key)
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <sql-builder-query :sql.sync="inheritedQuery" itemscope />
        <div v-if="server.configuration.variables">
          <v-card-title class="px-6">Variáveis Configuradas:</v-card-title>
          <div v-for="(value, key) in server.configuration.variables" :key="key" class="pb-1" tile>
            <v-row v-if="value && key" no-gutters class="px-6 mb-4">
              <v-col cols="3" class="pr-4">
                <v-text-field outlined hide-details append-icon="mdi-console-line" :value="key" readonly />
              </v-col>
              <v-col cols="9">
                <v-text-field outlined hide-details :value="value" readonly />
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else class="py-4 mx-6">
          <v-alert type="error" outlined dense>Não há variáveis configuradas.</v-alert>
        </div>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_REMOVE_QUERY, MUTATE_ADD_SQL, MUTATE_EDIT_SQL } from '@/modules/accounts/graphql'

export default {
  components: {
    SqlBuilder: () => import('@/components/base/SqlBuilder'),
    SqlBuilderQuery: () => import('@/components/base/SqlBuilderQuery'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: String,
    dialog: {
      type: Boolean,
      default: false
    },
    server: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    isHybrid: {
      type: Boolean,
      default: false
    },
    queryKeys: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    addSqlField: false,
    sqlKey: '',
    key: null,
    value: null,
    inherit: false,
    sql: {
      first_value: true,
      atemporal: true,
      query: '',
      date_field: '',
      date_filter_format: '',
      local_date_parser: '',
      realtime: ''
    },
    erpInheritedQuery: false,
    inheritedQuery: {},
    changesDialogEdit: false,
    changesDialogDelete: false,
    changesDialogInsert: false,
    changesMessage: ''
  }),
  methods: {
    async removeQuery(key) {
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_QUERY,
          variables: {
            id: this.server.accountId,
            index: this.server.index,
            key: key.toString()
          }
        })
        delete this.server.configuration.sqls[key]
        this.$emit('update:server', this.server)
        this.$alert({
          alert_message: `Query "${key}" removida`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.close()
        this.$router.go()
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao remover query',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.key = null
    },
    async addFields() {
      if (this.isHybrid && this.queryKeys.includes(this.sqlKey)) {
        return this.$alert({
          alert_message: 'Um dos servidores já possui esta query',
          alert_title: 'Aviso!',
          alert_color: 'warning',
          alert_icon: 'mdi-alert-circle'
        })
      }

      this.$setLogMessage(this.changesMessage)
      const queryName = this.sqlKey
      const newSql = Object.assign(
        {},
        { inherit: this.inherit },
        !this.inherit ? { first_value: this.sql.first_value } : null,
        !this.inherit ? { atemporal: this.sql.atemporal } : null,
        !this.inherit ? { query: this.sql.query } : null,
        !this.sql.atemporal ? { date_field: this.sql.date_field } : null,
        !this.sql.atemporal ? { date_filter_format: this.sql.date_filter_format } : null,
        !this.sql.atemporal ? { local_date_parser: this.sql.local_date_parser } : null
      )

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_SQL,
          variables: {
            id: this.server.accountId,
            server_index: this.server.index,
            sql: newSql,
            key: queryName
          }
        })
        this.server.configuration.sqls[queryName] = newSql
        this.addSqlField = false
        this.$alert({
          alert_message: 'Query adicionada com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.close()
        setTimeout(() => {
          this.$router.go()
        }, 500)
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao adicionar query',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async updateQuery(key, value) {
      this.$setLogMessage(this.changesMessage)
      const variables = Object.assign(
        {},
        { id: this.accountId },
        { serverIndex: this.server?.index },
        { key },
        { inherit: value?.inherit },
        { atemporal: value?.atemporal || false },
        { realtime: value?.realtime },
        !value?.inherit && value?.first_value !== undefined ? { first_value: value?.first_value } : { first_value: true },
        !value?.inherit && value?.query !== undefined ? { query: value?.query } : null,
        !value?.atemporal && value?.date_field !== undefined ? { date_field: value?.date_field } : null,
        !value?.atemporal && value?.date_filter_format !== undefined ? { date_filter_format: value?.date_filter_format } : null,
        !value?.atemporal && value?.local_date_parser !== undefined ? { local_date_parser: value?.local_date_parser } : null
      )

      try {
        await this.$apollo.mutate({
          mutation: MUTATE_EDIT_SQL,
          variables
        })
        this.close()
        this.$alert({
          alert_message: `Query "${key}" atualizada`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao atualizar query',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.key = null
    },
    openChangesDelete(key) {
      if (this.isDeploying) return this.removeQuery(key)
      this.changesDialogDelete = true
      this.key = key
    },
    openChangesEdit(key, value) {
      if (this.isDeploying) return this.updateQuery(key, value)
      this.changesDialogEdit = true
      this.key = key
      this.value = value
    },
    showErpQuery(key) {
      if (!this.server.erpServer.sqls[key]) {
        return this.$alert({
          alert_message: `Query "${key}" não encontrada`,
          alert_title: 'Aviso!',
          alert_color: 'warning',
          alert_icon: 'mdi-alert-circle'
        })
      }
      this.inheritedQuery = this.server.erpServer.sqls[key]
      this.inheritedQuery.key = key
      this.erpInheritedQuery = true
    },
    cleanFields() {
      this.sqlKey = ''
      this.inherit = false
      this.sql.first_value = true
      this.sql.atemporal = true
      this.sql.query = ''
      this.sql.date_field = ''
      this.sql.date_filter_format = ''
      this.sql.local_date_parser = ''
    },
    close() {
      this.changesDialogEdit = false
      this.changesDialogDelete = false
      this.changesDialogInsert = false
      this.cleanFields()
      return this.$emit('close')
    },
    saveInsertManager() {
      if (this.isDeploying) return this.addFields()
      this.changesDialogInsert = true
    }
  }
}
</script>
